import axios from "axios";

// const BASE_URL = `https://production-server.welinkjobs.com/api`
// export const base_domain = `https://production-server.welinkjobs.com`
// export const MessageHub = `https://production-server.welinkjobs.com/hubs`
// export const azure_domain = `https://webdemo.welinkjobs.com`
//export const azure_domain = `https://welinkjobsstorage.blob.core.windows.net/dev` not required

const BASE_URL = `https://webdemoapi.welinkjobs.com/api`
export const base_domain = `https://webdemoapi.welinkjobs.com`
export const MessageHub = `https://webdemoapi.welinkjobs.com/hubs`
export const azure_domain = `https://welinkjobs.com`
// export const azure_domain = `https://welinkjobsprodstorage.blob.core.windows.net/$web` not required
// export const azure_domain = `https://welinkjobsstorage.blob.core.windows.net/test` not required

// const BASE_URL = `https://localhost:7067/api`
// export const base_domain = `https://localhost:7067`
// export const MessageHub = `https://localhost:7067/hubs`
// export const azure_domain = `https://webdemo.welinkjobs.com`
// ---------------
// const BASE_URL = `https://server.welinkjobs.com/api`
// export const base_domain = `https://server.welinkjobs.com`
// export const MessageHub = `https://server.welinkjobs.com/hubs`
// export const azure_domain = `https://webdemo.welinkjobs.com`
// export const azure_domain = `https://welinkjobsstorage.blob.core.windows.net/dev` not required
// export const azure_domain = `https://welinkjobsstorage.z29.web.core.windows.net` not required
// ---------------
// const BASE_URL = `https://api.welinkjobs.com/api`
// export const base_domain = `https://api.welinkjobs.com`
// ---------------
// const BASE_URL = `https://testapi.welinkjobs.com/api`
// export const base_domain = `https://testapi.welinkjobs.com/`

// const token = localStorage.getItem('token')

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'access-control-allow-origin': '*',
        // Authorization: `Bearer ${token}`,
        Authorization: ``,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    }
})

axiosInstance.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

export default axiosInstance