import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "../API/axios"


const userId = localStorage.getItem('user-id')
const uservip = localStorage.getItem('user-vip')

export const getProfileInform = createAsyncThunk('profile/getProfile', async () => {
    const result = await axios.get(`Profile/UserProfileInfo/${uservip}`)
    return result.data
})


export const profileCountViewModel = createAsyncThunk('profile/modal', async () => {
    const result = await axios.get(`Profile/ProfileContactInfo/${uservip}`)
    return result.data
})
export const designationList = createAsyncThunk('profile/modal', async () => {
    const result = await axios.get(`Designation/GetAll`)
    return result.data
})

export const profileLastestPhotos = createAsyncThunk('profile/latestphotos', async () => {
})

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        loading: false,
        info: [],
        profileModal: [],
        error: null,
        tabName: '',
        showEditModal: true,
        notification: 0,
        designation: []
    },
    extraReducers: {
        [getProfileInform.pending]: (state) => {
            state.loading = true
        },
        [getProfileInform.fulfilled]: (state, action) => {
            state.loading = false
            state.info = [action.payload]
        },
        [profileCountViewModel.fulfilled]: (state, action) => {
            state.loading = false
            state.profileModal = [action.payload]
        },
        [designationList.fulfilled]: (state, action) => {
            state.loading = false
            state.designation = [action.payload]
        },
        [getProfileInform.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        }

    },
    reducers: {
        setTabName: (state, action) => {
            state.tabName = action.payload
        },
        setNotification: (state, action) => {
            state.notification = action.payload
        },
        setMessageList: (state, action) => {
            state.messageList = action.payload
        },
        setshowEditModal: (state, action) => { state.showEditModal = action.payload }

    }

})

export const {
    setTabName,
    setMessageList,
    setNotification,
    setshowEditModal
} = profileSlice.actions

export default profileSlice.reducer