import { createSlice } from "@reduxjs/toolkit"

export const localSlice = createSlice({
    name: "localData",
    initialState: {
        localToken: null
    },
    reducers: {
        setToken: (state, action) => { state.localToken = action.payload }
    }
})

export const {
    setToken
} = localSlice.actions

export default localSlice.reducer